import { dashboardService } from "./dashboardService";
import { documentService } from "./documentService";
import { needsListTemplateService } from "./needsListTemplateService";
import { needsListTemplateDocumentService } from "./needsListTemplateDocumentService";
import { lendingCompanyService } from "./lendingCompanyService";
import { lendingCompanyStateService } from "./lendingCompanyStateService";
import { lendingCompanyPropertyTypeService } from "./lendingCompanyPropertyTypeService";
import { lendingCompanyLoanTypeService } from "./lendingCompanyLoanTypeService";
import { lendingCompanyLoanProgramService } from "./lendingCompanyLoanProgramService";
import { contactService } from "./contactService";
import { employeeService } from "./employeeService";
import { notificationService } from "./notificationService";
import { loanService } from "./loanService";
import { loanEmployeeService } from "./loanEmployeeService";
import { loanBorrowerService } from "./loanBorrowerService";
import { loanReferrerService } from "./loanReferrerService";
import { borrowerNeedsListService } from "./borrowerNeedsListService";
import { borrowerNeedsListFileService } from "./borrowerNeedsListFilesService";
import { fileProcessorService } from "./fileProcessorService";
import { lenderCallLogService } from "./lenderCallLogService";
import { loanSummaryService } from "./loanSummaryService";
import { loanSummaryNeedsListService } from "./loanSummaryNeedsListService";
import { lenderCallLogNoteService } from "./lenderCallLogNoteService";
import { lenderLoanSummaryService } from "./lenderLoanSummaryService";
import { lenderSummaryService } from "./lenderSummaryService";
import { lenderSummaryNoteService } from "./lenderSummaryNoteService";
import { lenderSummaryNeedsListService } from "./lenderSummaryNeedsListService";
import { borrowerDashboardService } from "./borrowerDashboard";
import { loanNoteService } from "./loanNoteService";
import { accountService } from "./accountService";
import { lenderAttributeService } from "./lenderAttributeService";
import { lenderCriteriaService } from "./lenderCriteriaService";
import { lenderCriteriaParameterService } from "./lenderCriteriaParameterService";
import { lenderOfferService } from "./lenderOfferService";
import { lenderOfferFileService } from "./lenderOfferFileService";
import { lenderDashboardService } from "./lenderDashboard";
import { contactFileService } from "./contactFileService";
import { requestErrorMiddleware } from "../middlewares/requestErrorMiddleware";

export const serviceReducers = {
  [dashboardService.reducerPath]: dashboardService.reducer,
  [documentService.reducerPath]: documentService.reducer,
  [needsListTemplateService.reducerPath]: needsListTemplateService.reducer,
  [needsListTemplateDocumentService.reducerPath]: needsListTemplateDocumentService.reducer,
  [lendingCompanyService.reducerPath]: lendingCompanyService.reducer,
  [lendingCompanyStateService.reducerPath]: lendingCompanyStateService.reducer,
  [lendingCompanyPropertyTypeService.reducerPath]: lendingCompanyPropertyTypeService.reducer,
  [lendingCompanyLoanTypeService.reducerPath]: lendingCompanyLoanTypeService.reducer,
  [lendingCompanyLoanProgramService.reducerPath]: lendingCompanyLoanProgramService.reducer,
  [contactService.reducerPath]: contactService.reducer,
  [employeeService.reducerPath]: employeeService.reducer,
  [notificationService.reducerPath]: notificationService.reducer,
  [loanService.reducerPath]: loanService.reducer,
  [loanEmployeeService.reducerPath]: loanEmployeeService.reducer,
  [loanBorrowerService.reducerPath]: loanBorrowerService.reducer,
  [loanReferrerService.reducerPath]: loanReferrerService.reducer,
  [borrowerNeedsListService.reducerPath]: borrowerNeedsListService.reducer,
  [borrowerNeedsListFileService.reducerPath]: borrowerNeedsListFileService.reducer,
  [fileProcessorService.reducerPath]: fileProcessorService.reducer,
  [lenderCallLogService.reducerPath]: lenderCallLogService.reducer,
  [loanSummaryService.reducerPath]: loanSummaryService.reducer,
  [loanSummaryNeedsListService.reducerPath]: loanSummaryNeedsListService.reducer,
  [lenderCallLogNoteService.reducerPath]: lenderCallLogNoteService.reducer,
  [lenderLoanSummaryService.reducerPath]: lenderLoanSummaryService.reducer,
  [lenderSummaryService.reducerPath]: lenderSummaryService.reducer,
  [lenderSummaryNoteService.reducerPath]: lenderSummaryNoteService.reducer,
  [lenderSummaryNeedsListService.reducerPath]: lenderSummaryNeedsListService.reducer,
  [borrowerDashboardService.reducerPath]: borrowerDashboardService.reducer,
  [loanNoteService.reducerPath]: loanNoteService.reducer,
  [accountService.reducerPath]: accountService.reducer,
  [lenderAttributeService.reducerPath]: lenderAttributeService.reducer,
  [lenderCriteriaService.reducerPath]: lenderCriteriaService.reducer,
  [lenderCriteriaParameterService.reducerPath]: lenderCriteriaParameterService.reducer,
  [lenderOfferService.reducerPath]: lenderOfferService.reducer,
  [lenderOfferFileService.reducerPath]: lenderOfferFileService.reducer,
  [lenderDashboardService.reducerPath]: lenderDashboardService.reducer,
  [contactFileService.reducerPath]: contactFileService.reducer,
};

export const serviceMiddleware = [
  dashboardService.middleware,
  documentService.middleware,
  needsListTemplateService.middleware,
  needsListTemplateDocumentService.middleware,
  lendingCompanyService.middleware,
  lendingCompanyStateService.middleware,
  lendingCompanyPropertyTypeService.middleware,
  lendingCompanyLoanTypeService.middleware,
  lendingCompanyLoanProgramService.middleware,
  contactService.middleware,
  employeeService.middleware,
  notificationService.middleware,
  loanService.middleware,
  loanEmployeeService.middleware,
  loanBorrowerService.middleware,
  loanReferrerService.middleware,
  borrowerNeedsListService.middleware,
  borrowerNeedsListFileService.middleware,
  fileProcessorService.middleware,
  lenderCallLogService.middleware,
  loanSummaryService.middleware,
  loanSummaryNeedsListService.middleware,
  lenderCallLogNoteService.middleware,
  lenderLoanSummaryService.middleware,
  lenderSummaryService.middleware,
  lenderSummaryNoteService.middleware,
  lenderSummaryNeedsListService.middleware,
  borrowerDashboardService.middleware,
  loanNoteService.middleware,
  accountService.middleware,
  lenderAttributeService.middleware,
  lenderCriteriaService.middleware,
  lenderCriteriaParameterService.middleware,
  lenderOfferService.middleware,
  lenderOfferFileService.middleware,
  lenderDashboardService.middleware,
  contactFileService.middleware,
  requestErrorMiddleware,
];
